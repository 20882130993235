<template>
  <div class="dealer">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- add dialog for dealer -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure want to remove dealer from {{ delDealerName }}?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dodelDeals()"> Yes </v-btn>
          <v-btn text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ headTitle }}
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-select
                        required
                        :rules="rules.dealerType"
                        v-model="addDealer.dealerType"
                        :items="['AD', 'PI', 'Used']"
                        item-value="value"
                        item-text="text"
                        label="Dealer Type*"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        v-model="dealerJSON"
                        :items="dealerEntries"
                        :search-input.sync="searchDealerChange"
                        item-value="name"
                        item-text="name"
                        :loading="isLoading"
                        :disabled="!addDealer.dealerType"
                        placeholder="Dealer*"
                        prepend-icon="mdi-account"
                        return-object
                        @change="selectedDealer(dealerJSON)"
                      >
                      </v-autocomplete>
                      <p
                        v-if="selectedDealers.length === 0 && isDealerError"
                        class="v-messages__message"
                        style="margin-top: -16px;margin-left: 33px;"
                      >
                        Dealer required
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedDealers.length > 0">
                    <v-list class="dealer-listing" flat>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, i) in selectedDealers"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="item.name">
                            </v-list-item-title
                            >(Enrolled date:
                            <v-dialog width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.enrolledTime"
                                  readonly
                                  prepend-inner-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="item.enrolledTime">
                              </v-date-picker>
                            </v-dialog>
                            )
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-icon @click="deleteDealerSelected(i)"
                              >mdi-close</v-icon
                            >
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        label="Campaign*"
                        required
                        :rules="rules.campaign"
                        v-model="campaignJSON"
                        :items="campaignList"
                        :search-input.sync="searchCampaign"
                        item-value="name"
                        item-text="name"
                        prepend-icon="mdi-database-search"
                        return-object
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doaddDealer(addDealer)"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- filter search -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchDealer()"
          v-model="dealerSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchDealer()"
          @keyup.enter.native="searchDealer()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="openAddDealer()">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <!-- dealer data listing table -->
    <v-data-table
      :headers="headers"
      :items="campaignDealerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>
            <ol>
              <li
                style="margin: 2px 0"
                v-for="(item, i) in row.item.dealerList.slice(0, 3)"
                :key="i"
              >
                {{ item.name }}
              </li>
            </ol>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
// import postAxios from "../../../services/axios-post";
// import deleteAxios from "../../../services/axios-delete";
Vue.use(VueAxios, axios);
export default {
  data() {
    return {
      isLoading: false,
      search: null,
      dealerSearch: "",
      addDialog: false,
      headTitle: null,
      // editDialog: false,
      snackbar: false,
      snackMessage: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Campaign", value: "name" },
        { text: "Dealer", value: "dealer", sortable: false },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      campaignDealerList: [],
      addDealer: {
        dealerType: null,
        dealer: null,
        campaign: null,
      },
      rules: {
        dealerType: [v => !!v || "Dealer type required"],
        dealer: [v => !!v || "Dealer required"],
        campaign: [v => !!v || "Campaign required"],
      },
      dealerEntries: [],
      dealerJSON: { id: null, name: null },
      selectedDealers: [],
      campaignDealers: [],
      searchCampaign: null,
      campaignJSON: null,
      campaignList: [],
      deleteDialog: false,
      delDealerName: null,
      searchDealerChange: null,
      isDealerError: false,
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchDealerList();
    this.getCampiagn();
  },
  watch: {
    searchCampaign(val) {
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      // Lazily load input items
      this.getCampiagn(val);
    },
    searchDealerChange(val) {
      const search = { searchContent: val };
      this.dealerChange(search);
    },
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchDealerList();
    },
    handleChangeCalendar(data, mode, view) {
      console.log("hello", data, mode, view);
    },
    convertDate(date) {
      if (!date) {
        return "-";
      }
      var parsedDate = moment
        .utc(date)
        .local()
        .format("DD-MM-YYYY");
      return parsedDate;
    },
    dealerChange(search) {
      const self = this;
      if (
        this.addDealer.dealerType === "AD" ||
        this.addDealer.dealerType === "PI"
      ) {
        const isAuthorized =
          this.addDealer.dealerType === "AD"
            ? "isAuthorized=1"
            : "isAuthorized=0";
        getAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer?${isAuthorized}`,
          search,
          {
            status: "APPROVED",
          },
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dealerEntries = res.data.data.content;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else if (this.addDealer.dealerType === "Used") {
        getAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
          search,
          {
            status: "APPROVED",
          },
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dealerEntries = res.data.data.content;
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    openAddDealer() {
      this.addDialog = true;
      this.headTitle = "Add Dealer";
      this.resetAddItemInfo();
    },
    getCampiagn(val) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/campaignSystem/campaignData`,
        {
          searchContent: val,
        },
        this.token
      )
        .then(res => {
          if (res.data) {
            this.campaignList = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchDealer() {
      this.fetchDealerList();
    },
    fetchDealerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealerSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/campaignSystem/campaignData`,
        params
      )
        .then(res => {
          if (res.data) {
            self.campaignDealerList = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchDealerList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.addDialog = true;
      this.headTitle = "Edit Dealer";
    },
    setEditItemInfo(item) {
      if (item.dealerList.length > 0) {
        const delType = item.dealerList[0].authorizedFlag ? "AD" : "PI";
        this.addDealer.dealerType = delType ?? "";
        const search = { searchContent: "" };
        this.dealerChange({ search });
        let dealerIDListArr = [];
        for (let dealer of item.dealerList) {
          if (dealer) {
            this.campaignDealers.push(dealer.id);
            dealerIDListArr.push(dealer.id);
          }
        }
        item.dealerIdList = dealerIDListArr;
      }
      this.campaignJSON = item;
      item.dealerList = item.dealerList.map(dealer => ({
        ...dealer,
        enrolledTime: dealer.enrolledTime
          ? moment(dealer.enrolledTime).format("YYYY-MM-DD")
          : dealer.enrolledTime,
      }));
      this.selectedDealers = item.dealerList;
    },
    resetAddItemInfo() {
      this.addDealer.dealerType = null;
      this.campaignDealers = [];
      this.campaignJSON = null;
      this.selectedDealers = [];
      this.dealerJSON = null;
    },
    selectedDealer(dealer) {
      if (!this.selectedDealers.find(del => del.id === dealer.id)) {
        const dealerObj = {
          id: dealer.id,
          name: dealer.name,
          enrolledTime: moment().format("YYYY-MM-DD"),
        };
        this.selectedDealers.push(dealerObj);
        this.campaignDealers.push(dealerObj.id);
        this.dealerJSON = { id: null, name: null };
      }
    },
    deleteDealerSelected(index) {
      this.selectedDealers.splice(index, 1);
      this.campaignDealers.splice(index, 1);
    },
    async doaddDealer() {
      const self = this;
      try {
        this.isDealerError = this.campaignDealers.length > 0 ? false : true;
        if (this.$refs.form.validate() && !this.isDealerError) {
          self.campaignJSON.dealerIdList =
            self.campaignDealers.length > 0 ? self.campaignDealers : [];
          self.campaignJSON.dealerList = self.campaignJSON.dealerList.map(
            dealer => ({
              ...dealer,
              dealerId: dealer.id,
              enrolledDate: dealer.enrolledTime,
            })
          );
          self.campaignJSON.img = self.campaignJSON.img
            ? self.campaignJSON.img
                .split("https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/")
                .slice(-1)[0]
            : null;
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/campaignSystem/updCampaign`,
            self.campaignJSON
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.addDialog = false;
                this.resetAddItemInfo();
                self.fetchDealerList();
                // this.$refs.form.reset();
                this.snackbar = true;
                this.snackMessage = "Dealer added successfully";
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    delButtonClick(item) {
      this.delDealer = item;
      this.delDealerName = item.name;
      this.deleteDialog = true;
      this.campaignJSON = item;
    },
    dodelDeals() {
      this.campaignJSON.dealerIdList = [];
      this.campaignJSON.dealerList = [];
      this.campaignJSON.img = this.campaignJSON.img
        ? this.campaignJSON.img
            .split("https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/")
            .slice(-1)[0]
        : null;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/campaignSystem/updCampaign`,
        this.campaignJSON
      )
        .then(res => {
          if (res.data.httpStatus === "OK") {
            this.deleteDialog = false;
            this.fetchDealerList();
            this.snackbar = true;
            this.snackMessage = "Dealer delete successfully";
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
  },
};
</script>
<style lang="scss">
.dealer-listing {
  width: 100%;
  background-color: rgb(241, 241, 241) !important;
  border-radius: 5px !important;
  .v-list-item__content > * {
    flex: initial;
  }
  .v-input {
    width: 120px;
    margin-left: 10px;
  }
}

.v-list-item__title {
  flex: initial;
  margin-right: 16px;
}
.v-application .error--text {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-messages__message {
  color: red !important;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
</style>
